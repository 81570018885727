<template>
  <div>
    <content-background-cover
      :background-color='eventMainThemeColorBg'
      class='z-0 main-image-background'
      :top-offset='0'
      style='background-color: var(--darkColor)' />
    <div class='flex flex-row justify-center items-center ' style='height: calc(100vh - 12rem);'>
      <div class='text-center px-16 py-24 border rounded-lg shadow-md bg-white mx-4 lg:mx-auto lg:w-full lg:max-w-xl'>
        <img :src='eventConfigLogoImageUrl' class='h-10 inline-block mb-8' />
        <h1 class='text-lg uppercase font-semibold tracking-tight' :style='themeTextColorStyle'>Program Book</h1>
        <button 
          @click='downloadProgramButton'
          class='block w-full px-12 py-4 rounded-lg border uppercase mt-8 text-white font-medium hover:shadow-md' 
          :style='eventMainThemeColorBg'>
          View Program Book
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'

export default {
  name: 'ProgramBook',
  components: {
    ContentBackgroundCover,
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'themeTextColorStyle',
      'eventMainThemeColorBg',
    ]),
    downloadProgramUrl() {
      return 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/apsr2022-ondemand/program_book.pdf'
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    downloadProgramButton () {
     /* eslint-disable */
      try { downloadProgram.postMessage(this.downloadProgramUrl) } catch (err) { 
        // do nothing
      }
      /* eslint-enable */
      window.open(this.downloadProgramUrl, '_blank').focus()
    },

  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'ProgramBook'} })
      }
    })
  },
}
</script>

<style lang='scss' scoped>
  .main-image-background {
    background-image: url(https://d3r2ol85dktaw0.cloudfront.net/conferences/APSR2022/visual_day.png); 
    background-repeat: no-repeat; 
    background-size: cover;  
    background-position: center;
    opacity: 0.5;
    background-color: --var(--lightColor);
    background-blend-mode: soft-light;
  }
</style>
